<template>
	<div class="app-main__inner" v-loading="loading">
		<div class="container mb-3 dm-sans-font">
			<div
				class="flex flex-col md:flex-row justify-between align-items-center mb-3"
			>
				<div>
					<div
						class="flex justify-between align-items-center space-x-3 mb-3"
					>
						<span
							class="text-sm md:text-2xl font-semibold text-gray-900"
							>{{ $t("viewProductDetail") }}</span
						>
					</div>
				</div>
				<div>
					<span
						class="text-base font-semibold text-blue-900 cursor-pointer"
						@click="$router.go(-1)"
						>{{ $t("back") }}</span
					>
				</div>
			</div>
			<div class="p-4 bg-white">
				<div class="row">
					<div class="col-md-4">
						<h3 style="font-size: 21px" class="mt-2 font-semibold">
							{{ currentArticle.description }}
						</h3>
						<div
							class="bg-white single-product-img mt-3 cursor-pointer"
							v-on:click="showImages"
						>
							<el-carousel
								:interval="5000"
								indicator-position="none"
								v-if="currentArticle.images"
							>
								<el-carousel-item
									v-for="(item, i) in currentArticle.images"
									:key="i"
								>
									<span>
										<img
											class="carousel-cell-image"
											:src="item.image"
											data-zoomable=""
										/>
									</span>
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="flex justify-center mt-2">
							<button
								v-on:click="addProductCart"
								class="focus:outline-none mr-5 px-4 py-2 flex justify-center btn-yellow rounded"
								:disabled="prevNum < 1 && num < 1"
							>
								Beställ
							</button>
							<input-number
								:num="num"
								@onchange="onChangeInput"
							/>
							<!--							<el-input-number v-model="num" size="small" class="number-incremental"-->
							<!--											 @change="handleChange" :min="0"></el-input-number>-->
						</div>
					</div>
					<div class="col-md-5 mt-5 mb-10">
						<div class="bg-white flex flex-col space-y-10">
							<div
								v-if="
									currentArticle.product_long_description !==
									'nan'
								"
							>
								<div
									v-html="
										cleanData(
											currentArticle.product_long_description
										)
									"
								></div>
							</div>
						</div>
						<div class="row" v-if="articleVariationList.length > 0">
							<div class="col-md-12">
								<div class="bg-white variations mt-5">
									<product-variation-list
										:products="articleVariationList"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3 flex flex-col justify-between">
						<div class="flex flex-col space-y-3 bg-white mt-5">
							<div>
								<h2 class="text-sm font-semibold">
									{{ currentArticle.description }}
								</h2>
							</div>
							<p>
								{{ $t("articleNumber") }}:
								{{ currentArticle.article_number }}
							</p>
							<div
								class="flex space-x-4 items-center"
								v-if="
									GET_COMPANY_SETTING.hide_stock_quantity ===
										false &&
									hidden_fields.stock_quantity === false
								"
							>
								<span class="block"
									>{{ $t("disposableQty") }}:
									{{ currentArticle.quantity_in_stock }}</span
								>
							</div>
							<br />
							<p
								class="italics"
								v-if="hidden_fields.delivery_time === false"
							>
								<i
									>{{ $t("Delivery") }}:
									{{ currentArticle.delivery_time }}
									{{ $t("days") }}</i
								>
							</p>
							<p
								class="italics"
								v-if="
									currentArticle.estimated_delivery_date !==
										'' &&
									hidden_fields.delivery_time === false
								"
							>
								<i
									>{{ $t("estimatedDeliveryDate") }}:
									{{
										currentArticle.estimated_delivery_date
									}}</i
								>
							</p>
							<div v-if="!currentArticle?.has_discount">
								<p class="price !text-red-600">
									{{
										parseAmount(currentArticle.sales_price)
									}}:-
								</p>
							</div>
							<div v-else>
								<p class="price !text-red-600">
									{{
										applyDiscount(
											parseAmount(
												currentArticle.sales_price
											),
											currentArticle?.discount_value,
											currentArticle?.discount_type ===
												"PERCENT"
										)
									}}
									:-
								</p>
							</div>
							<div v-if="currentArticle?.has_discount">
								<p class="priceSlash !text-black">
									<del
										>{{
											parseAmount(
												currentArticle.sales_price
											)
										}}:-</del
									>
								</p>

								<p
									class="priceDiscount !text-black"
									v-if="currentArticle?.has_discount"
								>
									{{ $t("discounted") }}
									<span
										class="font-black !text-red-500 text-[.8rem] font-bold"
									>
										{{
											convertDiscountValueToPercentage(
												currentArticle?.discount_value,
												currentArticle?.discount_type,
												currentArticle.sales_price
											)
										}}%</span
									>
								</p>
							</div>
							<!-- <div
								v-if="
									hasDiscount() === true &&
									hidden_fields.discounted_price === false
								"
							>
								<p class="price">
									{{
										parseAmount(
											currentArticle.discounted_price
										)
									}}{{ currentArticle.currency }}
								</p>
								<div class="div-discount-price">
									<span class="discounted-price mr-2"
										>{{ currentArticle.sales_price
										}}{{ currentArticle.currency }}</span
									>
									<span
										class="percentage-off"
										v-if="getPercentage() !== ''"
										>{{ getPercentage() }}%</span
									>
								</div>
							</div> -->

							<div
								v-if="
									currentArticle.hasOwnProperty(
										'standard_price'
									)
								"
							>
								<div>
									<span class="font-semibold"
										>{{ $t("standard_price") }}:
										{{
											currentArticle.standard_price
										}}</span
									>
								</div>
							</div>
							<div
								style="margin-bottom: 12px"
								v-if="
									currentArticle.has_customer_resale_price &&
									hidden_fields.recommended_price === false
								"
							>
								<div>
									<span class="font-semibold">{{
										currentArticle.consumer_resale_field_title
									}}</span>
								</div>
								<div class="position-relative">
									<p
										class="customer-resale-price font-semibold text-blue-500"
									>
										{{
											currentArticle.consumer_resale_amount
										}}:-
									</p>
								</div>
							</div>
							<div v-if="currentArticle.is_paired_product">
								<div class="m4">
									<span class="font-semibold"
										>{{ $t("productPackageText") }}:
										{{
											currentArticle.product_package_bundle_number
										}}</span
									>
								</div>
							</div>
							<div
								v-if="currentArticle.has_customer_resale_price"
							>
								<div class="">
									<span class="font-semibold"
										>{{ $t("profitMargin") }}:
										{{ calculateProfitMargin() }}</span
									>
								</div>
							</div>

							<div v-if="currentArticle.product_pdf" class="mt-3">
								<p class="text-base text-blue-600">
									<a
										:href="currentArticle.product_pdf"
										target="_blank"
										download
										class="flex"
										><img
											src="../../assets/images/icons/file.svg"
											class="cursor-pointer flex w-full"
											style="height: 18px; width: 18px"
											:alt="$t('productPdf')"
										/>
										<span>{{ $t("productPdf") }}</span>
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="flex flex-col mt-5">
					<h2 class="text-xl font-semibold">
						{{ $t("specification") }}
					</h2>
					<hr class="border-4 border-blue-800" />
					<div
						:class="key % 2 === 1 ? 'bg-gray' : ''"
						class="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-2 mt-2"
						v-for="(item, key) in GET_SINGLE_ARTICLE.specifications"
						:key="key"
					>
						<p class="text-gray-500">{{ item.title }}</p>
						<p class="text-gray-500">{{ item.text }}</p>
					</div>

					<div
						v-if="
							GET_SINGLE_ARTICLE.hasOwnProperty(
								'article_accessories'
							) &&
							GET_SINGLE_ARTICLE.hasOwnProperty(
								'recommended_article'
							)
						"
					>
						<hr
							class="border-4 border-blue-800 mb-3"
							v-if="
								GET_SINGLE_ARTICLE.article_accessories.length >
									0 ||
								GET_SINGLE_ARTICLE.recommended_article.length >
									0
							"
						/>
					</div>
				</div>

				<div
					v-if="
						GET_SINGLE_ARTICLE.hasOwnProperty(
							'article_accessories'
						) &&
						GET_SINGLE_ARTICLE.hasOwnProperty('recommended_article')
					"
				>
					<div
						class="row"
						v-if="
							GET_SINGLE_ARTICLE.article_accessories.length > 0 ||
							GET_SINGLE_ARTICLE.recommended_article.length > 0
						"
					>
						<div
							:class="{
								'col-md-7':
									GET_SINGLE_ARTICLE.article_accessories
										.length > 0 &&
									GET_SINGLE_ARTICLE.recommended_article
										.length > 0,
								'col-md-12':
									GET_SINGLE_ARTICLE.article_accessories
										.length <= 0 &&
									GET_SINGLE_ARTICLE.recommended_article
										.length > 0,
							}"
						>
							<div class="bg-white recommend-br">
								<div class="recommended-product-headline">
									<p class="headline-text">
										{{ $t("recommendedProduct") }}
									</p>
								</div>
								<recommended-product
									:products="
										GET_SINGLE_ARTICLE.recommended_article
									"
									:user_type="'buyer'"
								/>
							</div>
						</div>
						<div
							:class="{
								'col-md-5':
									GET_SINGLE_ARTICLE.article_accessories
										.length > 0 &&
									GET_SINGLE_ARTICLE.recommended_article
										.length > 0,
								'col-md-12':
									GET_SINGLE_ARTICLE.article_accessories
										.length > 0 &&
									GET_SINGLE_ARTICLE.recommended_article
										.length <= 0,
							}"
						>
							<div class="bg-white recommend-br">
								<div class="recommended-product-headline">
									<p class="headline-text">
										{{ $t("accessories") }}
									</p>
								</div>
								<recommended-product
									:products="
										GET_SINGLE_ARTICLE.article_accessories
									"
									:user_type="'buyer'"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import RecommendedProduct from "./component/RecommendedProduct";
import productVariationList from "./component/productVariationComponents/productVariationList";

import inputNumber from "./component/inputNumber";
import { BUYER_HIDDEN_FIELDS } from "../../services/constant";
import {
	getUserSetting,
	getUserSettingHideFieldStatus,
} from "../../services/storage-window";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
import { convertDiscountValueToPercentage } from "../../utils/utils";

Vue.use(VueViewer);
export default {
	name: "checkout",
	components: {
		RecommendedProduct,
		productVariationList,
		inputNumber,
	},
	data() {
		return {
			convertDiscountValueToPercentage,
			delivery: {
				name: "",
				address: "",
				zipCode: "",
				city: "",
				country: "",
				email: "",
				reference: "",
				orderNumber: "",
				message: "",
			},
			bankIdNumber: "",
			loading: true,
			useBilling: false,
			processing: false,
			check: false,
			cancelBtn: false,
			openBankId: false,
			bankIDinfo: {},
			companyID: null,
			num: 0,
			prevNum: 0,
			price: 0,
			discounted: 0,
			percentOff: 0,
			currentArticle: {},
			articleVariationList: [],
			hidden_fields: {
				...JSON.parse(JSON.stringify(BUYER_HIDDEN_FIELDS)),
			},
			productImages: [],
		};
	},
	computed: {
		...mapGetters({
			GET_ARTICLES: "customer/GET_ARTICLES",
			CART_PRODUCT: "customer/CART_PRODUCT",
			CART_AMOUNT: "customer/CART_AMOUNT",
			GET_BILLING_INFO: "customer/GET_BILLING_INFO",
			GET_COUNTRY: "customer/GET_COUNTRY",
			GET_BANK_ID: "customer/GET_BANK_ID",
			CART_AMOUNT_INC_VAT: "customer/CART_AMOUNT_INC_VAT",
			CART_INC_VAT_AMOUNT: "customer/CART_INC_VAT_AMOUNT",
			GET_COMPANY_SETTING: "customer/GET_COMPANY_SETTINGS",
			GET_AUTH_USER: "portal/GET_AUTH_USER",
			GET_SINGLE_ARTICLE: "customer/GET_SINGLE_ARTICLE",
			GET_ARTICLE_SPECIFICATION: "customer/GET_ARTICLE_SPECIFICATION",
			GET_ARTICLE_PARAMS: "customer/GET_ARTICLE_PARAMS",
			BUYER_SETTINGS: "customer/GET_USER_SETTINGS",
			HIDE_FIELD_STATUS: "customer/GET_USER_SETTINGS_HIDE_FIELD_STATUS",
		}),
	},
	watch: {
		num(newVal, oldVal) {
			this.prevNum = oldVal;
		},
	},
	beforeDestroy() {
		window.Bus.$off("get-bank-id");
	},
	mounted() {
		const { company_id, product_id } = this.$route.params;
		this.companyID = company_id;
		let pagenum = 1;
		if (this.GET_ARTICLE_PARAMS.pagenum !== "") {
			pagenum = this.GET_ARTICLE_PARAMS.pagenum;
		}
		let URL = `?company_id=${company_id}&limit=${this.GET_ARTICLE_PARAMS.limit}&page=${pagenum}`;
		if (this.GET_ARTICLE_PARAMS.searchname !== "") {
			URL += `&search=${this.GET_ARTICLE_PARAMS.searchname}`;
		}
		this.$store.dispatch("customer/getArticles", { pageUrl: URL });
		this.getCompanyInfo(company_id);
		this.getArticles();
		if (company_id === "invalid_company") {
			this.$services.helpers.notification(
				this.$t("no_company_text"),
				"error",
				this
			);
		}
		window.Bus.$on("revert-input-to-zero", (e) => {
			this.num = 0;
		});
		window.Bus.$on("set-current-variation", (e) => {
			this.setCurrentArticle(e);
		});

		window.Bus.$on("trigger-hidden-field-event", (e) => {
			this.disabledFields(e);
		});
	},
	beforeRouteUpdate(to, from, next) {
		// react to route changes...
		// don't forget to call next()
		next();
	},
	methods: {
		applyDiscount(price, discount, isPercent = true) {
			if (isPercent) {
				let discountAmount = price * (discount / 100);
				let discountedPrice = price - discountAmount;
				return discountedPrice.toFixed(2);
			} else {
				return Number(price) - Number(discount);
			}
		},
		showImages() {
			if (this.productImages.length > 0) {
				this.$viewerApi({
					images: this.productImages,
				});
			}
		},
		disabledFields: function (status) {
			if (getUserSetting()) {
				if (status === true) {
					let fields = getUserSetting();
					let hidden_fields = fields.hidden_fields;
					let visible_fields = fields.visible_fields;
					visible_fields.map((item) => {
						this.hidden_fields[item] = false;
					});
					hidden_fields.map((item) => {
						this.hidden_fields[item] = true;
					});
				} else {
					Object.keys(this.hidden_fields).map((item) => {
						this.hidden_fields[item] = false;
					});
				}
			}
		},
		setCurrentArticle(article) {
			this.currentArticle = article;
		},
		parseAmount(amount) {
			let am = String(amount).split(".");
			if (am.length > 0) {
				if (parseFloat(am[1]) === 0.0) {
					return am[0];
				} else {
					return amount;
				}
			} else {
				return amount;
			}
		},
		getCompanyInfo(id) {
			this.companyID = id;
			const payload = {
				id: id,
			};
			this.$store.dispatch("customer/getCompanySettings", payload);
		},
		getArticles() {
			this.loading = true;
			this.$store
				.dispatch("customer/getSingleArticles", {
					pageUrl: `/${this.$route.params.product_id}/?company_id=${this.companyID}`,
				})
				.then(() => {
					this.loading = false;
					this.currentArticle = this.GET_SINGLE_ARTICLE;
					if (this.currentArticle.article_variations.length > 0) {
						this.articleVariationList =
							this.currentArticle.article_variations;
						this.currentArticle.article_variations.push(
							this.currentArticle.id
						);
						this.articleVariationList = [
							...new Set(this.currentArticle.article_variations),
						];
					}
					this.productImages = [];
					if (this.GET_SINGLE_ARTICLE.images.length > 0) {
						this.GET_SINGLE_ARTICLE.images.map((item) => {
							this.productImages.push(item.image);
						});
					}
					this.disabledFields(getUserSettingHideFieldStatus());
				})
				.catch((err) => {
					this.loading = false;
					this.$store.commit("customer/UPDATE_SINGLE_ARTICLE", {});
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_CUS", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		onChangeInput(mode) {
			this.num = mode;
		},
		addProductCart() {
			// if (parseInt(this.GET_SINGLE_ARTICLE.quantity_in_stock) > 0) {
			if (this.num > 0) {
				window.Bus.$emit("add-to-cart", {
					product: this.currentArticle,
					qty: this.num,
				});
			} else {
				this.$store.commit("customer/REMOVE_CART_PRODUCT", {
					product: this.GET_SINGLE_ARTICLE,
					remove: true,
					qty: this.num,
				});
				window.Bus.$emit("message", { data: this.$t("itemRemove") });
				this.prevNum = 0;
			}
			// }
		},
		handleChange(e) {
			if (e === undefined) {
				this.num = 0;
			} else {
				this.num = e;
			}
		},
		hasDiscount() {
			let currentDate = moment().format("YYYY-MM-DD");
			if (
				this.GET_SINGLE_ARTICLE.scheduled_campaign_start_date !==
					null &&
				this.GET_SINGLE_ARTICLE.scheduled_campaign_end_date !== null &&
				this.GET_SINGLE_ARTICLE.scheduled_campaign_start_date !== "" &&
				this.GET_SINGLE_ARTICLE.scheduled_campaign_end_date !== ""
			) {
				let startDate = moment(
					this.GET_SINGLE_ARTICLE.scheduled_campaign_start_date
				).format("YYYY-MM-DD");
				let endDate = moment(
					this.GET_SINGLE_ARTICLE.scheduled_campaign_end_date
				).format("YYYY-MM-DD");
				if (currentDate >= startDate && currentDate <= endDate) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		getPercentage() {
			let discountedPrice =
				parseFloat(this.GET_SINGLE_ARTICLE.sales_price) -
				parseFloat(this.GET_SINGLE_ARTICLE.discounted_price);
			let total =
				(discountedPrice /
					parseFloat(this.GET_SINGLE_ARTICLE.sales_price)) *
				100;
			let percentile = Math.ceil(total);
			if (percentile) {
				return percentile;
			} else {
				return "";
			}
		},
		cleanData: function (name) {
			if (["nan"].includes(name)) {
				return "";
			} else {
				return name;
			}
		},
		calculateProfitMargin() {
			let net_revenue =
				parseFloat(this.GET_SINGLE_ARTICLE.consumer_resale_amount) -
				parseFloat(this.GET_SINGLE_ARTICLE.sales_price);
			let profit = parseFloat(
				(parseFloat(net_revenue) /
					parseInt(this.GET_SINGLE_ARTICLE.consumer_resale_amount)) *
					100
			).toFixed(2);
			return profit;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.custom-rem {
	padding: 5rem;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.recommend-br {
	border: 1px solid #00000033;
	border-radius: 5px !important;
	padding-left: 10px;
	padding-right: 10px;
}

.form-control,
.form-select {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: 0.175rem 0.75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.total-summary {
	border-bottom: 1px solid #ddd;
	padding: 7px 0;
}

.el-loading-mask {
	.el-loading-spinner {
		display: unset !important;
	}
}

.el-check-out-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 9999999999999999999999;
}

.eid-cancel-btn {
	margin-top: 11px !important;
	padding: 6px !important;
}

.btn-yellow {
	border: 1px solid #e8b105;
	color: #e8b105;
	font-weight: bold;
}

.price {
	font-size: 30px;
	color: #413683;
	font-weight: 600;
	text-align: center;
	margin-top: 20px;
}

.priceSlash,
.priceDiscount {
	font-size: 20px;
	color: #413683;
	font-weight: 600;
	text-align: center;
}

.priceDiscount {
	font-size: 0.9rem;
	margin-bottom: 1rem;
}

.single-product-img .el-carousel__container {
	height: 15rem !important;
}

.number-incremental {
	top: 6px;
}

.bg-gray {
	background: rgba(217, 217, 217, 0.2) !important;
}

.div-discount-price {
	margin-top: 0px;
	font-size: 18px;
	text-align: center;
}

.discounted-price {
	color: #495057;
	text-decoration: line-through;
}

.percentage-off {
	color: red;
}

.customer-resale-price {
	position: absolute;
	right: 153px;
	font-size: 18px;
}

.medium-zoom-image {
	cursor: pointer;
	cursor: zoom-in;
	position: absolute;
	transition: transform 0.3s cubic-bezier(0.2, 0, 0.2, 1) !important;
	z-index: 99;
}

.recommended-product-headline {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 15px;
	font-weight: 500;
}

.recommended-product-headline > p {
	color: #4667a7 !important;
}
</style>
