<template>
	<div class="carousel-cell">
		<div class="stacked stack-image" v-if="loading === false">
			<img :src="image.image" v-if="articles.images.length > 0" class="img-slider cursor-pointer"
				 alt="slide image"
				 v-on:click="openProductDetail"/>
		</div>
		<div class="stacked stack-image" v-else>
			<el-skeleton-item variant="image" class="shimmer-img"/>
		</div>
		<div>
			<p class="font-bold  text-md text-base carousel-text cursor-pointer" v-on:click="openProductDetail"
			   v-if="loading === false">
				{{ articles.description }}
			</p>
			<p class="carousel-cell-price font-semibold text-blue" v-if="loading === false">{{
					this.getPrice()
				}}:-</p>
			<p v-else>
				<el-skeleton-item variant="p" class="product-desc-slider"/>
				<el-skeleton-item variant="p" class="product-desc-slider"/>
			</p>
		</div>
	</div>
</template>

<script>
// import mediumZoom from "medium-zoom";
import moment from "moment";

export default {
	name: "variations",
	props: {
		product_id: {
			type: Number,
			required: true
		},
		user_type: {
			type: String,
			default: 'buyer'
		}
	},
	data: function () {
		return {
			articles: {
				images: []
			},
			loading: true,
			image: {
				image: ''
			}
		}
	},
	methods: {
		getArticle: function () {
			this.loading = true
			this.$store.dispatch('customer/getGenericSingleArticles', {pageUrl: `/${this.product_id}/?company_id=${this.$route.params.company_id}`})
				.then(resp => {
					this.loading = false
					this.articles = resp.data
					if (this.articles.images.length > 0) {
						this.image = this.articles.images[0]
					}
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getSellerArticle: function () {
			this.loading = true
			this.$store.dispatch('portal/getSingleArticle', {url: `/${this.product_id}/`})
				.then(resp => {
					this.loading = false
					this.articles = resp.data
					if (this.articles.images.length > 0) {
						this.image = this.articles.images[0]
					}
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getPrice: function () {
			let currentDate = moment().format('YYYY-MM-DD')
			if (this.articles.scheduled_campaign_start_date !== null && this.articles.scheduled_campaign_end_date !== null && this.articles.scheduled_campaign_start_date !== '' && this.articles.scheduled_campaign_end_date !== '') {
				let startDate = moment(this.articles.scheduled_campaign_start_date).format('YYYY-MM-DD')
				let endDate = moment(this.articles.scheduled_campaign_end_date).format('YYYY-MM-DD')
				if (currentDate >= startDate && currentDate <= endDate) {
					return this.articles.discounted_price
				} else {
					return this.articles.sales_price
				}
			} else {
				return this.articles.sales_price
			}
		},
		openProductDetail() {
			window.Bus.$emit('set-current-variation', this.articles)
		}
	},
	mounted() {
		let self = this
		setTimeout(function () {
			if (self.user_type === 'buyer') {
				self.getArticle()
			} else {
				self.getSellerArticle()
			}

		}, 500)
	}
}
</script>

<style scoped>

.stack-image {
	height: 100px;
	width: 100px;
	margin: auto;
	padding-top: 11px;
	margin-bottom: 10px;
}

.carousel-cell {
	height: 200px !important;
	width: 200px !important;
	align-items: center;
	justify-content: center;
	padding: 9px;
	position: relative;
	/*width: 33%;*/
}

.carousel-cell-price {
	/*position: absolute;*/
	/*right: 95px;*/
	font-weight: 700;
	bottom: 26px;
	text-align: center;
	margin-top: 13px;
}

.carousel.is-fullscreen .carousel-cell {
	height: 100%;
}

.carousel-text {
	font-size: 13px;
	line-height: 13px;
	text-align: center;
	word-break: break-word;
}

.carousel-cell img {
	display: block;
	max-height: 100%;
}

.shimmer-img {
	height: 87px;
	margin: auto;
	display: table;
	width: 100px;
}


.img-slider {
	margin: auto;
	display: table;
	height: 100px;
	width: 100px;
}

.product-desc-slider {
	width: 70%;
	height: 10px;
	margin: auto;
	display: table;
	margin-bottom: 5px;
}

.text-blue {
	color: #1e40af !important;
}
</style>
